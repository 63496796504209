import React, { useState, useEffect } from "react";
import "./Header.css";
import logo from "../assets/images/Logo-blk.png";
import { Navbar, Nav, Container, Button, Dropdown } from "react-bootstrap";
import AuthModal from "./AuthModal";
import { auth } from "../firebaseConfig"; // Import Firebase auth
import { onAuthStateChanged, signOut } from "firebase/auth"; // Import onAuthStateChanged and signOut

function Header() {
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null); // State to hold user information
  const [authMode, setAuthMode] = useState("signup"); // 'signup' or 'login'
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser); // Update user state based on authentication status
    });
    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      alert("Sign out error: " + error.message); // Alert the error
    }
  };
  const toggleAuthMode = () => {
    setAuthMode((prevMode) => (prevMode === "signup" ? "login" : "signup"));
  };

  return (
    <header className="header">
      <Navbar bg="transparent" expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              width="213.34"
              className="d-inline-block align-top"
              alt="Styld logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="d-flex justify-content-between align-items-center w-100 flex-lg-row">
              <Nav.Link href="/community" className="events-link">
                <span className="sparkle">🪞</span> Community <span className="sparkle"></span>
              </Nav.Link>
              <Nav.Link href="/demo">Live Demos</Nav.Link>
              <Dropdown>
                <Dropdown.Toggle as={Nav.Link}>For Retailers</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="https://docs.styld.ai">API</Dropdown.Item>
                  <Dropdown.Item href="/widget">Widget</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Nav.Link href="/survey">STYLD Quiz</Nav.Link>

              <Nav.Link href="/contact">Contact Us</Nav.Link>
              <div className="flex-grow-1"></div>
              <div className="ml-auto">
                {user ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="link"
                      id="user-dropdown"
                      style={{ border: "none", padding: 0 }}
                    >
                      <img
                        src={
                          user.photoURL ||
                          "https://pathwayactivities.co.uk/wp-content/uploads/2016/04/Profile_avatar_placeholder_large-circle-300x300.png"
                        } // Use user photo or placeholder
                        alt="User Profile"
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          verticalAlign: "middle",
                        }} // Style for round image
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleSignOut}>
                        Sign Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Button
                    style={{
                      background: "transparent",
                      border: "2px solid black",
                      borderRadius: "25px",
                      color: "black",
                    }}
                    variant="secondary"
                    onClick={handleShow}
                  >
                    Sign In
                  </Button>
                )}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <AuthModal
        show={showModal}
        isSignup={authMode === "signup"}
        toggleAuthMode={toggleAuthMode}
        handleClose={handleClose}
      />
    </header>
  );
}

export default Header;