import React, { useState } from "react";
import { Container, Row, Col, Tab, Nav, Form, Button } from "react-bootstrap";
import { db } from "../firebaseConfig";
import { doc, setDoc } from "firebase/firestore";
import DemoB2B from "../demo/demoB2B/demoB2B";
import DemoB2C from "../demo/demoB2C/demoB2C";
import InfiniteLooper from "./infiniteLooper/infiniteLooper";
import cover from "../assets/images/cover.png";
import EventsTab from './EventsTab';
import './home.css'; // Added import for home.css

const Home = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [activeTab, setActiveTab] = useState("b2c");

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        await setDoc(doc(db, "waitlist", email), { email, name, phone });
        setSuccessMessage("Successfully joined the waitlist!");
        setEmail("");
        setName("");
        setPhone("");
      } catch (error) {
        console.error("Error adding to waitlist: ", error);
        setSuccessMessage("Failed to join the waitlist. Please try again.");
      }
    }
  };

  return (
    <Container fluid className="home-container container">
      <div className="animation-container">
        <InfiniteLooper speed="11" direction="right">
          <div className="contentBlock contentBlock--one">
            <img
              src={cover}
              width="2000"
              className="d-inline-block align-top"
              alt="Styld logo"
            />{" "}
          </div>
          <div className="contentBlock contentBlock--one">
            <img
              src={cover}
              width="2000"
              className="d-inline-block align-top"
              alt="Styld logo"
            />{" "}
          </div>
        </InfiniteLooper>
      </div>

      <Row className="main-content">
        <Col md={6} className="text-content">
          <div className="storefront">
            <h1 className="store-sign mb-4">
              Shopping, personalized for you.
            </h1>
            <p className="store-tagline mb-4">
              Discover your personal style with our AI-powered personal shopper.
              Join the waitlist to be in the know.
            </p>

            <Form onSubmit={handleEmailSubmit} className="waitlist-form mt-4">
              <Form.Control
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="waitlist-input"
              />
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="waitlist-input"
                required
              />
              <Form.Control
                type="tel"
                placeholder="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="waitlist-input"
              />
              <Button
                type="submit"
                variant="dark"
                className="waitlist-button storefront-button"
              >
                Join Waitlist
              </Button>
            </Form>
            {successMessage && <p className="mt-3 success-message">{successMessage}</p>}
          </div>

          <div className="action-buttons-container mt-4">
            <Button href="/demo" variant="dark" className="storefront-button">Try Demo</Button>
            <Button href="https://calendly.com/v-styld/30min" variant="dark" className="storefront-button">Schedule Demo</Button>
            <Button href="https://docs.styld.ai" variant="dark" className="storefront-button">Get API Key</Button>
          </div>
        </Col>

        <Col md={6} className="video-content">
          <div className="video-container">
            <iframe
              src="https://www.youtube.com/embed/MvfG46hhH6I?autoplay=1"
              allowFullScreen
            ></iframe>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;