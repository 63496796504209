import React from 'react';
import { useParams } from 'react-router-dom';
import ChatWidgetComponent from './widget/ChatWidget';

const FullScreenWidget = () => {
  const { storeId } = useParams();

  return (
    <div style={{
      height: '100vh',
      width: '100vw',
      overflow: 'hidden',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }}>
      <ChatWidgetComponent
        storeId={storeId || 'all'}
        isPreview={false}
        isOnWebsite={true}
        isFullScreen={true}
        hideStoreSelection={storeId || false}
      />
    </div>
  );
};

export default FullScreenWidget;