import React from 'react';
import DemoB2B from '../demo/demoB2B/demoB2B';

const DemoB2BPage = () => {
  return (
    <div className="container">
      <h1>Side-by-Side Comparison Demo</h1>
      <DemoB2B />
    </div>
  );
};

export default DemoB2BPage;