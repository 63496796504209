import React, { useState, useEffect, useMemo } from "react";
import "./survey.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import ListGroup from "react-bootstrap/ListGroup"
import Button from "react-bootstrap/Button"
import { auth, db } from "../firebaseConfig";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import AuthModal from "../components/AuthModal";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import images from '../imageImports';
import { Container, Row, Col, Card, Popover, OverlayTrigger } from 'react-bootstrap';

function Survey() {
  const questions = useMemo(() => [
    {
      question: "Create an account or log in",
      type: "auth",
    },
    {
      question: "What is your name?",
      type: "text",
      id: "name",
    },
    {
      question: "What clothing are you interested in?",
      type: "select",
      options: [
        { id: "Mens clothing styles and fits", text: "Men" },
        { id: "Womens clothing styles and fits", text: "Women" },
        { id: "Both mens and womens clothing", text: "Both" }
      ],
    },
    {
      question: "Which fabrics are you more drawn to?",
      type: "multiSelect",
      options: [
        { id: "Soft, breathable cotton for casual wear", image: "4a.png" },
        { id: "Durable, smooth leather for outerwear", image: "4b.png" },
        { id: "Luxurious, smooth satin for evening wear", image: "4c.png" },
        { id: "Stretchy, lightweight material for form-fitting outfits", image: "4d.png" },
        { id: "Mesh and stretchy blend for added dimension", image: "4e.png" },
        { id: "Stretchy, breathable material for active or leisure wear", image: "4f.png" },
        { id: "Lightweight, open weave knit for layering", image: "4g.png" },
        { id: "Soft, warm brushed wool blend for cold weather", image: "4h.png" },
        { id: "Soft, slightly stretchy cotton knit", image: "4i.png" },
        { id: "Voluminous, textured shaggy faux fur", image: "4j.png" },
        { id: "Smooth, silky satin with a glossy finish", image: "4k.png" },
        { id: "Thick, warm wool blend knit for cold weather", image: "4l.png" },
      ],
    },
    {
      question: "What clothing tones do you think look good on you?",
      type: "multiSelect",
      options: [
        { id: "Light pastel blue and crisp white, soft and airy", image: "5a.png" },
        { id: "Solid black, sleek and sophisticated", image: "5b.png" },
        { id: "Muted earthy greens and browns with soft floral patterns", image: "5c.png" },
        { id: "Rich medium gray with dark brown trim, neutral and grounded", image: "5d.png" },
        { id: "Vibrant fuchsia pink, bold and energetic", image: "5e.png" },
        { id: "Bright white and vivid blue vertical stripes, fresh contrast", image: "5f.png" },
        { id: "Muted steel blue with warm beige accents, cool and balanced", image: "5g.png" },
        { id: "Bold fuchsia pink with neutral gray, striking contrast", image: "5h.png" },
        { id: "Deep chocolate brown with thin white stripes, warm and rich", image: "5i.png" },
        { id: "Mix of earthy tones with bright green, vibrant yet grounded", image: "5j.png" },
        { id: "Bright lime green with orange accents, lively and eye-catching", image: "5k.png" },
        { id: "Clean white paired with soft pale blue, fresh and airy", image: "5l.png" },
      ],
    },
    {
      question: "What would you wear for comfort?",
      type: "multiSelect",
      options: [
        { id: "Vibrant blue and pink sports bra and leggings set for active performance", image: "6a.png" },
        { id: "Soft cropped t-shirt with loose, wide-legged jeans for casual comfort", image: "6b.png" },
        { id: "Light v-neck sweater with white shorts for laid-back lounging", image: "6c.png" },
        { id: "Checkered sports bra and leggings set for athleisure comfort", image: "6d.png" },
        { id: "Navy blue fleece-lined jogger set for ultimate lounging", image: "6e.png" },
        { id: "White coordinated activewear set for light exercise or casual wear", image: "6f.png" },
        { id: "Lightweight tank top with comfortable shorts for warm weather leisure", image: "6g.png" },
        { id: "Sleeveless shirt and black shorts for simple, casual comfort", image: "6h.png" },
        { id: "Textured knit tank top with leather pants for edgy comfort", image: "6i.png" },
        { id: "Oversized cardigan with relaxed pants for cozy, stylish comfort", image: "6j.png" },
        { id: "Matching sweatshirt and sweatpants set for maximum lounging comfort", image: "6k.png" },
        { id: "Breathable knit top with voluminous pants for airy, relaxed comfort", image: "6l.png" },
      ],
    },
    {
      question: "What would you wear to your place of work?",
      type: "multiSelect",
      options: [
        { id: "Black Nike hooded sweatshirt for casual, cool-weather wear", image: "7a.png" },
        { id: "Navy blue cropped pinstripe vest for sophisticated business-casual", image: "7b.png" },
        { id: "Light beige trench coat for versatile, weather-resistant style", image: "7c.png" },
        { id: "White lace blouse with puffed sleeves for romantic, formal wear", image: "7d.png" },
        { id: "Gray checked blazer with eclectic patches for contemporary style", image: "7e.png" },
        { id: "Classic beige trench coat for timeless, versatile protection", image: "7f.png" },
        { id: "Loose-fit lime green tank top for casual, warm-weather wear", image: "7g.png" },
        { id: "Red sleeveless muscle tee with bold text for active, casual style", image: "7h.png" },
        { id: "Black ribbed sleeveless top with orange detailing for edgy comfort", image: "7i.png" },
        { id: "Black hooded knit cardigan with ivory stripes for cozy layering", image: "7j.png" },
        { id: "Forest green sweatsuit set for casual, comfortable cool-weather wear", image: "7k.png" },
        { id: "Mustard yellow crochet top for breezy, bohemian warm-weather style", image: "7l.png" },
      ],
    },
    {
      question: "What would you wear on a date?",
      type: "multiSelect",
      options: [
        { id: "Fitted black top with flame graphic and loose jeans for edgy casual", image: "8a.png" },
        { id: "Navy blue suit with white turtleneck for polished, preppy style", image: "8b.png" },
        { id: "Teal corset top with denim mini skirt for flirty, youthful look", image: "8c.png" },
        { id: "Oversized black graphic tee with wide-leg trousers for relaxed chic", image: "8d.png" },
        { id: "Long black trench coat over sweater and trousers for sleek sophistication", image: "8e.png" },
        { id: "Checked jacket with green sweater and cream trousers for smart casual", image: "8f.png" },
        { id: "Green print shirt with brown cargo pants for bold, casual style", image: "8g.png" },
        { id: "Oversized beige hoodie and sweatpants set for comfortable, monochromatic athleisure", image: "8h.png" },
        { id: "White shirt with thin black stripes and black trousers for minimalist chic", image: "8i.png" },
        { id: "Loose-fitting white t-shirt with baggy jeans for a relaxed, casual look", image: "8j.png" },
        { id: "Black muscle tee with Ed Hardy print and cargo pants for edgy casual", image: "8k.png" },
        { id: "Fitted black turtleneck with high-waisted trousers for sleek, modern style", image: "8l.png" },
      ],
    },
    {
      question: "What would be your revenge outfit?",
      type: "multiSelect",
      options: [
        { id: "Red turtleneck with asymmetrical black mini skirt for bold, modern look", image: "9a.png" },
        { id: "Layered denim outfit with cropped top for edgy, futuristic style", image: "9b.png" },
        { id: "Soft pink floral jacquard evening gown for elegant, feminine allure", image: "9c.png" },
        { id: "Form-fitting plaid jumpsuit for retro-inspired, polished appearance", image: "9d.png" },
        { id: "Monochromatic beige hoodie and sweatpants for cozy, neutral athleisure", image: "9e.png" },
        { id: "Black multi-zipper jacket with tie for sleek, avant-garde edge", image: "9f.png" },
        { id: "Vibrant floral shirt with matching pants for breezy, summer-ready style", image: "9g.png" },
        { id: "Striped t-shirt with bold plaid shorts for casual, sporty vibe", image: "9h.png" },
        { id: "Pink knit tank top with relaxed jeans for effortless, comfortable chic", image: "9i.png" },
        { id: "White t-shirt with colorful shoulder stripes and beige plaid shorts for trendy streetwear", image: "9j.png" },
        { id: "Abstract print shirt with black trousers for sleek, modern impact", image: "9k.png" },
        { id: "Bright colored dress with unique cut-outs for daring, attention-grabbing style", image: "9l.png" },
      ],
    },
    {
      question: "Think of us as your Retail Therapy. What do you want to tell us about your style?",
      type: "textarea",
      id: "styleDescription",
    },
    {
      question: "What size do you typically wear and what's your skin tone?",
      type: "sizeAndSkin",
      fields: [
        { 
          id: "topSize", 
          label: "Top Size",
          options: ["XS", "S", "M", "L", "XL", "XXL", "XXXL"]
        },
        { 
          id: "bottomSize", 
          label: "Bottom Size",
          options: ["24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "38", "40", "42", "44"]
        },
      ],
      skinTones: [
        { id: "Very light skin tone", color: "#F5D0B9" },
        { id: "Light skin tone", color: "#E8B998" },
        { id: "Medium-light skin tone", color: "#D39B7B" },
        { id: "Medium skin tone", color: "#C68642" },
        { id: "Medium-dark skin tone", color: "#A56C43" },
        { id: "Dark skin tone", color: "#71361E" },
        { id: "Very dark skin tone", color: "#4A2511" },
      ],
    },
  ], []);

  const [currentQuestion, setCurrentQuestion] = useState(() => {
    const savedQuestion = localStorage.getItem('currentQuestion');
    return savedQuestion ? Math.min(parseInt(savedQuestion, 10), questions.length - 1) : 0;
  });
  const [answers, setAnswers] = useState({});
  const [user, setUser] = useState(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [isSurveyComplete, setIsSurveyComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneValue, setPhoneValue] = useState()
  const [showResults, setShowResults] = useState(false);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const [hasAttemptedNext, setHasAttemptedNext] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authMode, setAuthMode] = useState('signup'); // 'signup' or 'login'

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        setIsAuthenticated(true);
        const docRef = doc(db, `users/${user.uid}/survey/answers`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setAnswers(data);
          if (data["Create an account or log in"] && data["Create an account or log in"].phone) {
            setPhoneValue(data["Create an account or log in"].phone);
          }
        }
      } else {
        setUser(null);
        setIsAuthenticated(false);
        setCurrentQuestion(0);
        const savedAnswers = localStorage.getItem("surveyAnswers");
        if (savedAnswers) {
          const parsedAnswers = JSON.parse(savedAnswers);
          setAnswers(parsedAnswers);
          if (parsedAnswers["Create an account or log in"] && parsedAnswers["Create an account or log in"].phone) {
            setPhoneValue(parsedAnswers["Create an account or log in"].phone);
          }
        }
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (isAuthenticated && currentQuestion === 0) {
      handleNext();
    }
  }, [isAuthenticated, currentQuestion]);

  useEffect(() => {
    const validateAnswer = () => {
      const currentQ = questions[currentQuestion];
      const currentAnswer = answers[currentQ.question];

      if (currentQ.type === "textarea" || (currentQ.type === "sizeAndSkin" && currentQ.fields.some(field => field.id.includes("Size")))) {
        setIsNextButtonEnabled(true);
        setErrorMessage("");
        return;
      }

      if (!currentAnswer) {
        setIsNextButtonEnabled(false);
        return;
      }

      switch (currentQ.type) {
        case "contact":
          setIsNextButtonEnabled(Object.keys(currentAnswer).length === currentQ.fields.length);
          break;
        case "sizeAndSkin":
          setIsNextButtonEnabled(!!currentAnswer.skinTone);
          break;
        case "select":
          setIsNextButtonEnabled(Array.isArray(currentAnswer) && currentAnswer.length === 1);
          break;
        case "multiSelect":
          setIsNextButtonEnabled(Array.isArray(currentAnswer) && currentAnswer.length > 0);
          break;
        case "text":
          setIsNextButtonEnabled(typeof currentAnswer === 'string' && currentAnswer.trim() !== "");
          break;
        default:
          setIsNextButtonEnabled(true);
          break;
      }
    };

    validateAnswer();
    setHasAttemptedNext(false);
  }, [currentQuestion, answers, questions]);

  const handleNext = () => {
    setHasAttemptedNext(true);
    if (isNextButtonEnabled || (currentQuestion === 0 && isAuthenticated)) {
      if (currentQuestion < questions.length - 1) {
        const nextQuestion = currentQuestion + 1;
        setCurrentQuestion(nextQuestion);
        localStorage.setItem('currentQuestion', nextQuestion.toString());
      } else {
        handleSurveyCompletion();
      }
    } else {
      const currentQ = questions[currentQuestion];
      switch (currentQ.type) {
        case "contact":
          setErrorMessage("Please fill in all contact fields.");
          break;
        case "sizeAndSkin":
          setErrorMessage("Please select a skin tone.");
          break;
        case "select":
          setErrorMessage("Please select one option.");
          break;
        case "multiSelect":
          setErrorMessage("Please select at least one option.");
          break;
        case "text":
          setErrorMessage("Please provide a valid answer.");
          break;
        default:
          setErrorMessage("Please provide an answer before moving to the next question.");
          break;
      }
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      const prevQuestion = currentQuestion - 1;
      setCurrentQuestion(prevQuestion);
      localStorage.setItem('currentQuestion', prevQuestion.toString());
    }
  };

  const handleAnswer = (answer) => {
    const updatedAnswers = { ...answers };
    const currentQ = questions[currentQuestion];
    const questionKey = currentQ.question;

    if (currentQ.type === "multiSelect") {
      if (!Array.isArray(updatedAnswers[questionKey])) {
        updatedAnswers[questionKey] = [];
      }
      const index = updatedAnswers[questionKey].findIndex(a => a === answer.id);
      if (index > -1) {
        updatedAnswers[questionKey].splice(index, 1);
      } else {
        updatedAnswers[questionKey].push(answer.id);
      }

      if (currentQuestion === 2) {
        const newGenderPreference = updatedAnswers[questionKey];
        const questionsToReset = questions.slice(3, 9).map(q => q.question);
        questionsToReset.forEach(q => {
          if (updatedAnswers[q] && Array.isArray(updatedAnswers[q])) {
            updatedAnswers[q] = updatedAnswers[q].filter(option => {
              const optionIndex = questions.find(question => question.question === q).options.findIndex(o => o.id === option);
              return (newGenderPreference.includes("Womens clothing styles and fits") && optionIndex < 6) ||
                     (newGenderPreference.includes("Mens clothing styles and fits") && optionIndex >= 6);
            });
          }
        });
      }
    } else if (currentQ.type === "select") {
      updatedAnswers[questionKey] = [answer.id];
    } else if (currentQ.type === "auth") {
      if (!updatedAnswers[questionKey]) {
        updatedAnswers[questionKey] = {};
      }
      if (answer.id === "phone") {
        if (phoneValue) {
          updatedAnswers[questionKey][answer.id] = phoneValue;
        }
      } else {
        if (answer.value) {
          updatedAnswers[questionKey][answer.id] = answer.value;
        }
      }
    } else if (currentQ.type === "sizeAndSkin") {
      if (!updatedAnswers[questionKey]) {
        updatedAnswers[questionKey] = {};
      }
      updatedAnswers[questionKey][answer.id] = answer.value;
    } else if (currentQ.type === "text" || currentQ.type === "textarea") {
      updatedAnswers[questionKey] = answer;
    }

    Object.keys(updatedAnswers).forEach(key => {
      if (updatedAnswers[key] === undefined || updatedAnswers[key] === null) {
        delete updatedAnswers[key];
      } else if (typeof updatedAnswers[key] === 'object') {
        Object.keys(updatedAnswers[key]).forEach(subKey => {
          if (updatedAnswers[key][subKey] === undefined || updatedAnswers[key][subKey] === null) {
            delete updatedAnswers[key][subKey];
          }
        });
      }
    });

    setAnswers(updatedAnswers);

    if (!user) {
      localStorage.setItem("surveyAnswers", JSON.stringify(updatedAnswers));
    } else {
      const docRef = doc(db, `users/${user.uid}/survey/answers`);
      setDoc(docRef, updatedAnswers, { merge: true });
    }
  };

  const handleAuthSuccess = async (user, phoneNumber) => {
    setUser(user);
    setIsAuthenticated(true);
    setShowAuthModal(false);

    // Save the user's email and phone number to the survey answers
    const updatedAnswers = { ...answers };
    updatedAnswers["Create an account or log in"] = {
      email: user.email,
      phone: phoneNumber || null
    };
    setAnswers(updatedAnswers);

    // Save answers to Firestore
    const docRef = doc(db, `users/${user.uid}/survey/answers`);
    await setDoc(docRef, updatedAnswers, { merge: true });

    // Move to the next question
    handleNext();
  };

  const handleSurveyCompletion = () => {
    setIsSurveyComplete(true);
    if (!user) {
      localStorage.setItem("surveyAnswers", JSON.stringify(answers));
      setShowAuthModal(true);
    } else {
      const docRef = doc(db, `users/${user.uid}/survey/answers`);
      setDoc(docRef, answers);
      setShowResults(true);
    }
    localStorage.removeItem('currentQuestion');
    // onComplete(); // Call the onComplete prop to notify the parent
  };

  const handleAuthModalClose = () => {
    if (!user) {
      setShowAuthModal(false);
      setIsSurveyComplete(false);
    }
  };

  const getFilteredOptions = (options) => {
    const genderQuestion = "What clothing are you interested in?";
    const genderPreference = answers[genderQuestion] || [];

    if (genderPreference.includes("Womens clothing styles and fits") && genderPreference.includes("Mens clothing styles and fits")) {
      return options;
    } else if (genderPreference.includes("Womens clothing styles and fits")) {
      return options.slice(0, 6);
    } else if (genderPreference.includes("Mens clothing styles and fits")) {
      return options.slice(-6);
    }
    return options;
  };

  const renderResults = () => {
    return (
      <Container className="survey-results">
            <h2 className="text-center mb-4">Thanks for answering the survey!</h2>
            <div className="text-center mb-4">
              <Button href="/demo" variant="dark" size="lg">Try STYLD Search</Button>
            </div>
            <Card>
              <Card.Body className="row">
                {questions.map((q, index) => (
                  <div key={index} className="col-4 pb-4">
                    <Card.Title>{q.question}</Card.Title>
                    <Card.Text>
                      {q.type === 'auth' && answers[q.question] ? (
                        <span>Email: {answers[q.question].email}</span>
                      ) : q.type === 'multiSelect' || q.type === 'select' ? (
                        Array.isArray(answers[q.question]) ? answers[q.question].join(', ') : 'No answer provided'
                      ) : q.type === 'sizeAndSkin' ? (
                        Object.entries(answers[q.question] || {}).map(([key, value]) => (
                          <span key={key} className="d-block">
                            <strong>{key}:</strong> {value}
                          </span>
                        ))
                      ) : (
                        answers[q.question] || 'No answer provided'
                      )}
                    </Card.Text>
                    {/* {index < questions.length - 1 && <hr />} */}
                  </div>
                ))}
              </Card.Body>
            </Card>
      </Container>
    );
  };

  const renderAuthQuestion = () => {
    if (isAuthenticated) {
      return (
        <div className="auth-question-container">
          <h2 className="auth-question-title">Welcome back!</h2>
          <p className="auth-logged-in-message">You are logged in as {user.email}</p>
          <button className="auth-continue-button" onClick={() => setCurrentQuestion(1)}>Continue Survey</button>
        </div>
      );
    }
    return (
      <div className="auth-question-container">
        <h2 className="auth-question-title">Welcome to STYLD</h2>
        <p className="auth-question-text">Sign up or log in to start your style journey</p>
        <div className="auth-buttons-container">
          <button className="auth-button auth-button-signup" onClick={() => { setAuthMode('signup'); setShowAuthModal(true); }}>Sign Up</button>
          <button className="auth-button auth-button-login" onClick={() => { setAuthMode('login'); setShowAuthModal(true); }}>Log In</button>
        </div>
      </div>
    );
  };

  const toggleAuthMode = () => {
    setAuthMode(prevMode => prevMode === 'signup' ? 'login' : 'signup');
  };

  const renderSurveyContent = () => {
    if (showResults) {
      return renderResults();
    }

    if (!questions || questions.length === 0) {
      return <div>Error: No questions available</div>;
    }

    if (currentQuestion < 0 || currentQuestion >= questions.length) {
      return <div>Error: Invalid question index</div>;
    }

    const currentQ = questions[currentQuestion];

    if (currentQ.type === "auth") {
      return renderAuthQuestion();
    }

    return (
      <>
        <h3 className="survey-title">Let us get to know you.</h3>
        <h1 className="question text-center">{currentQ.question}</h1>
        {currentQ.type === "multiSelect" && (
          <p className="text-center">You can select multiple options</p>
        )}
        {hasAttemptedNext && !isNextButtonEnabled && errorMessage && (
          <div className="error-message text-center text-danger mb-3" style={{fontSize: '1.2rem', fontWeight: 'bold'}}>{errorMessage}</div>
        )}
        <div className="d-flex flex-wrap justify-content-center">
          {currentQ.type === "text" && (
            <input
              type="text"
              value={answers[currentQ.question] || ""}
              onChange={(e) => handleAnswer(e.target.value)}
              className="form-control"
            />
          )}
          {(currentQ.type === "select" || currentQ.type === "multiSelect") &&
            getFilteredOptions(currentQ.options).map((option, index) => {
              const isSelected = Array.isArray(answers[currentQ.question]) && answers[currentQ.question].includes(option.id);
              return (
                <div
                  key={index}
                  className="survey-option"
                  onClick={() => handleAnswer(option)}
                  style={{
                    cursor: "pointer",
                    padding: "10px",
                    margin: "10px",
                    flexGrow: "1",
                    flexBasis: "calc(33% - 20px)",
                    maxWidth: "calc(33% - 20px)",
                    border: isSelected ? '2px solid #007bff' : '2px solid #ccc',
                    backgroundColor: isSelected ? 'rgba(0, 123, 255, 0.1)' : 'transparent',
                    borderRadius: '10px',
                    textAlign: 'center',
                    fontSize: '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transition: 'all 0.3s ease',
                    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                    opacity: 1,
                    pointerEvents: 'auto',
                  }}
                >
                  {option?.image && (
                    <img 
                      src={images[option?.image]} 
                      alt={option.text} 
                      style={{
                        width: '100%',
                        height: '200px',
                        objectFit: 'contain',
                        marginBottom: '10px',
                        borderRadius: '8px',
                      }}
                    />
                  )}
                  <span style={{ color: 'black' }}>{option?.image ? option.text : option.id}</span>
                </div>
              );
            })}
          {currentQ.type === "textarea" && (
            <textarea
              value={answers[currentQ.question] || ""}
              onChange={(e) => handleAnswer(e.target.value)}
              className="form-control"
              rows="4"
            />
          )}
          {currentQ.type === "sizeAndSkin" && (
            <div>
              {currentQ.fields.map((field) => (
                <div key={field.id} className="mb-3">
                  <label htmlFor={field.id}>{field.label}</label>
                  <select
                    id={field.id}
                    value={(answers[currentQ.question] && answers[currentQ.question][field.id]) || ""}
                    onChange={(e) => handleAnswer({ id: field.id, value: e.target.value })}
                    className="form-control"
                  >
                    <option value="">Select {field.label}</option>
                    {field.options.map((option) => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </select>
                </div>
              ))}
              <div className="d-flex justify-content-around mt-3">
                {currentQ.skinTones.map((tone) => (
                  <div
                    key={tone.id}
                    onClick={() => handleAnswer({ id: "skinTone", value: tone.id })}
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: tone.color,
                      borderRadius: "50%",
                      cursor: "pointer",
                      border: (answers[currentQ.question] && answers[currentQ.question].skinTone === tone.id) ? "3px solid #007bff" : "none",
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="navigation-buttons d-flex justify-content-around">
          <button
            className="custom-button"
            onClick={handlePrevious}
            disabled={currentQuestion === 0}
          >
            <svg
              width="46"
              height="66"
              viewBox="0 0 46 66"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ opacity: currentQuestion === 0 ? 0.3 : 1 }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.79533 36.4891L36.4837 65.6814L45.1543 58.3846L14.8012 32.8407L45.1543 7.29679L36.4837 0L1.79533 29.1923C0.645767 30.16 -2.21568e-05 31.4724 -2.21568e-05 32.8407C-2.21568e-05 34.2091 0.645767 35.5214 1.79533 36.4891Z"
                fill="black"
              />
            </svg>
          </button>
          <button
            className="custom-button"
            onClick={handleNext}
            disabled={false}
          >
            <svg
              width="46"
              height="66"
              viewBox="0 0 46 66"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ 
                opacity: isNextButtonEnabled ? 1 : 0.3,
                transform: "rotate(180deg)"
              }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.79533 36.4891L36.4837 65.6814L45.1543 58.3846L14.8012 32.8407L45.1543 7.29679L36.4837 0L1.79533 29.1923C0.645767 30.16 -2.21568e-05 31.4724 -2.21568e-05 32.8407C-2.21568e-05 34.2091 0.645767 35.5214 1.79533 36.4891Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      </>
    );
  };

  return (
    <div className="Survey mt-4">
      <main>
        {!showResults && (
          <div className="progress-bar">
            <ProgressBar
              animated
              now={((currentQuestion + 1) / questions.length) * 100}
              label={`${Math.round(((currentQuestion + 1) / questions.length) * 100)}%`}
              variant="dark"
              style={{
                border: "2px solid black",
                marginLeft: "85px",
                marginRight: "85px",
                borderRadius: "0px",
                height: "20px",
                backgroundColor: "transparent",
              }}
            />
          </div>
        )}
        <div className="survey-content">{renderSurveyContent()}</div>
        <AuthModal 
          show={showAuthModal} 
          handleClose={() => setShowAuthModal(false)}
          onAuthSuccess={handleAuthSuccess}
          isSignup={authMode === 'signup'}
          toggleAuthMode={toggleAuthMode}
        />
      </main>
    </div>
    
  );
}

export default Survey;