import React, { useState, useEffect, useCallback, useRef } from 'react';
import './EmbedDesigner.css';
import ChatWidgetComponent from '../widget/ChatWidget';
import { toast } from "react-toastify";
const fontFamilies = [
  'Inter, sans-serif',
  'Arial, sans-serif',
  'Georgia, serif',
  'Times New Roman, serif',
  'Courier New, monospace',
];

const EmbedDesigner = () => {
  const [primaryColor, setPrimaryColor] = useState('#007bff');
  const [secondaryColor, setSecondaryColor] = useState('#0056b3');
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [textColor, setTextColor] = useState('#333333');
  const [buttonTextColor, setButtonTextColor] = useState('#ffffff');
  const [fontFamily, setFontFamily] = useState(fontFamilies[0]);
  const [borderRadius, setBorderRadius] = useState('20px');
  const [storeId, setStoreId] = useState('zara');
  const [email, setEmail] = useState('');
  const [searchTop, setSearchTop] = useState(50);
  const [buttonText, setButtonText] = useState('Style Assistant');

  const previewContainerRef = useRef(null);

  const generateEmbedCode = useCallback(() => {
    return `
<script src="https://styld.ai/widget.js"></script>
<div id="chat-widget-container"></div>
<script>
  document.addEventListener('DOMContentLoaded', function() {
    window.initChatWidget('chat-widget-container', {
      'primary-color': '${primaryColor}',
      'secondary-color': '${secondaryColor}',
      'background-color': '${backgroundColor}',
      'text-color': '${textColor}',
      'button-text-color': '${buttonTextColor}',
      'font-family': '${fontFamily}',
      'border-radius': '${borderRadius}',
      'email': '${email}',
      'search-top': '${searchTop}',
      'store-id': '${storeId}',
      'button-text': '${buttonText}'
    });
  });
</script>
  `.trim();
  }, [primaryColor, secondaryColor, backgroundColor, textColor, buttonTextColor, fontFamily, borderRadius, email, searchTop, storeId, buttonText]);

  const [embedCode, setEmbedCode] = useState(generateEmbedCode());

  useEffect(() => {
    setEmbedCode(generateEmbedCode());
  }, [generateEmbedCode]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(embedCode).then(() => {
      toast.success('Embed code copied to clipboard!');
    });
  };

  const updateWidget = useCallback(() => {
    if (window.initChatWidget && previewContainerRef.current) {
      // Clear previous widget
      previewContainerRef.current.innerHTML = '';
      
      // Initialize new widget with current settings
      window.initChatWidget('preview-container', {
        'primary-color': primaryColor,
        'secondary-color': secondaryColor,
        'background-color': backgroundColor,
        'text-color': textColor,
        'button-text-color': buttonTextColor,
        'font-family': fontFamily,
        'border-radius': borderRadius,
        'email': email,
        'search-top': searchTop.toString(),
        'store-id': storeId,
        'button-text': buttonText
      });
    }
  }, [primaryColor, secondaryColor, backgroundColor, textColor, buttonTextColor, fontFamily, borderRadius, email, searchTop, storeId, buttonText]);

  useEffect(() => {
    if (!window.initChatWidget) {
      const script = document.createElement('script');
      script.src = 'https://styld.ai/widget.js';
      script.async = true;
      script.onload = () => {
        updateWidget();
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    } else {
      updateWidget();
    }
  }, [updateWidget]);

  useEffect(() => {
    // Hide the global widget when entering the EmbedDesigner
    const globalWidget = document.getElementById('global-chat-widget-container');
    if (globalWidget) {
      globalWidget.style.display = 'none';
    }

    return () => {
      // Show the global widget when leaving the EmbedDesigner
      if (globalWidget) {
        globalWidget.style.display = 'block';
      }
    };
  }, []);

  const [showAuthModal, setShowAuthModal] = useState(false);
  const [isSignup, setIsSignup] = useState(true);

  return (
    <div className="embed-designer">
      <h1>Customize Your Widget</h1>
      <div className="designer-container">
        <div className="customization-panel">
          <label>
            Primary Color:
            <input type="color" value={primaryColor} onChange={(e) => { setPrimaryColor(e.target.value); updateWidget(); }} />
          </label>
          <label>
            Secondary Color:
            <input type="color" value={secondaryColor} onChange={(e) => { setSecondaryColor(e.target.value); updateWidget(); }} />
          </label>
          <label>
            Background Color:
            <input type="color" value={backgroundColor} onChange={(e) => { setBackgroundColor(e.target.value); updateWidget(); }} />
          </label>
          {/* <label>
            Text Color:
            <input type="color" value={textColor} onChange={(e) => { setTextColor(e.target.value); updateWidget(); }} />
          </label> */}
          <label>
            Button Text Color:
            <input type="color" value={buttonTextColor} onChange={(e) => { setButtonTextColor(e.target.value); updateWidget(); }} />
          </label>
          {/* <label>
            Font Family:
            <select value={fontFamily} onChange={(e) => { setFontFamily(e.target.value); updateWidget(); }}>
              {fontFamilies.map((font) => (
                <option key={font} value={font}>{font}</option>
              ))}
            </select>
          </label> */}
          {/* <label>
            Border Radius:
            <input type="text" value={borderRadius} onChange={(e) => { setBorderRadius(e.target.value); updateWidget(); }} />
          </label> */}
          <label>
            Store ID:
            <input type="text" value={storeId} onChange={(e) => { setStoreId(e.target.value); updateWidget(); }} />
          </label>
          <label>
            *Email <small>(Email Of Shopper Connected to Styld Account)</small>
            <input type="email" placeholder='Blank for Anonymous Search' value={email} onChange={(e) => { setEmail(e.target.value); updateWidget(); }} />
          </label>
          <label>
            Default Alternative Product Results:
            <input type="number" value={searchTop} onChange={(e) => { setSearchTop(e.target.value); updateWidget(); }} min="1" max="100" />
          </label>
          <label>
            Button Text:
            <input type="text" value={buttonText} onChange={(e) => { setButtonText(e.target.value); updateWidget(); }} />
          </label>
        </div>
        <div className="preview-panel" style={{ width: '400px' }}> {/* Set a fixed width */}
          <h2>Live Preview</h2>
          <div className="widget-preview">
            <ChatWidgetComponent
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              backgroundColor={backgroundColor}
              textColor={textColor}
              buttonTextColor={buttonTextColor}
              fontFamily={fontFamily}
              borderRadius={borderRadius}
              email={email}
              searchTop={parseInt(searchTop)}
              storeId={storeId}
              buttonText={buttonText}
              isPreview={true} // New prop to indicate preview mode
              showAuthModal={showAuthModal}
              setShowAuthModal={setShowAuthModal}
              isSignup={isSignup}
              setIsSignup={setIsSignup}
            />
          </div>
        </div>
      </div>
      <div className="embed-code mt-4">
        <h2>Embed Code</h2>
        <textarea value={embedCode} readOnly rows={20} />
        <button onClick={copyToClipboard}>Copy to Clipboard</button>
      </div>
    </div>
  );
};

export default EmbedDesigner;