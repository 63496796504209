import React from 'react';
import { useParams } from 'react-router-dom';
import DemoB2C from '../demo/demoB2C/demoB2C';

const DemoB2CPage = () => {
  const { store } = useParams();
  return (
    <div className="container">
      <h1>{store.toUpperCase()} Demo</h1>
      <DemoB2C initialStore={store} />
    </div>
  );
};

export default DemoB2CPage;