import React, { useState, useEffect } from "react";
import "./demoB2B.css";
import Alert from 'react-bootstrap/Alert'; // Add this import
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import AdamPic from "../../assets/images/demo/Adam.png";
import JosePic from "../../assets/images/demo/Jose.png";
import HannahPic from "../../assets/images/demo/Hannah.png";
import MariaPic from "../../assets/images/demo/Maria.png";
import PatriciaPic from "../../assets/images/demo/Patricia.png";
import Logo from "../../assets/images/Logo-blk.png";
import Spinner from "react-bootstrap/Spinner";
import userFullBody from "../../assets/images/demo/user-full-body.png";
import prodThumbnail from "../../assets/images/demo/productThumbnail.png";
import algoliasearch from 'algoliasearch/lite';
import axios from 'axios';
import { auth } from "../../firebaseConfig";
import { trackSearch, trackProductClick } from '../../analytics';

const DemoB2B = () => {
  const [selectedPersonality, setSelectedPersonality] = useState(() => localStorage.getItem('selectedPersonality') || null);
  const [timeElapsed, setTimeElapsed] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [likedProducts, setLikedProducts] = useState(() => JSON.parse(localStorage.getItem('likedProductsB2B')) || {});
  const [sharedProducts, setSharedProducts] = useState(() => JSON.parse(localStorage.getItem('sharedProductsB2B')) || {});
  const [viewedProducts, setViewedProducts] = useState(() => JSON.parse(localStorage.getItem('viewedProductsB2B')) || {});
  const [traditionalResults, setTraditionalResults] = useState(() => JSON.parse(localStorage.getItem('traditionalResults')) || []);
  const [searchTerm, setSearchTerm] = useState(() => localStorage.getItem('searchTermB2B') || '');
  const [styledResults, setStyledResults] = useState(() => JSON.parse(localStorage.getItem('styledResults')) || []);
  const [isStyledLoading, setIsStyledLoading] = useState(false);
  const [isTraditionalLoading, setIsTraditionalLoading] = useState(false);
  const [userEmail, setUserEmail] = useState(() => localStorage.getItem('userEmail') || "");
  const [searchSuggestions] = useState([
    "I'm going for vacation in Italy, what can I wear?",
    "Brunch outfits",
    "What can I wear to a dinner date?"
  ]);
  const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);
  const [hasSearched, setHasSearched] = useState(false);
  const [availableStores] = useState(["all", "revolve", "zara", "h&m", "ssense"]);
  const [selectedStore, setSelectedStore] = useState("all");
  const [searchReason, setSearchReason] = useState("");
  const personalities = [
    {
      name: "Jose",
      image: JosePic,
      email: "jose@example.com",
      description: "Jose prefers luxurious fabrics, bold colors, and a mix of classic and edgy styles. He values comfort and makes stylish statements.",
    },
    {
      name: "Adam",
      image: AdamPic,
      email: "adam@example.com",
      description: "Adam favors soft, warm fabrics, relaxed fits, and a mix of classic and bold styles. He appreciates minimalism with occasional statement pieces.",
    },
    {
      name: "Hannah",
      image: HannahPic,
      email: "hannah@example.com",
      description: "Hannah likes soft, breathable fabrics, earthy tones, and a mix of sophisticated and playful styles. She values comfort and feminine touches.",
    },
    {
      name: "Maria",
      image: MariaPic,
      email: "maria@example.com",
      description: "Maria appreciates luxurious fabrics, vibrant colors, and a blend of classic and trendy styles. She values versatility and making bold statements.",
    },
    {
      name: "Patricia",
      image: PatriciaPic,
      email: "patricia@example.com",
      description: "Patricia prioritizes comfort and practicality, with a mix of androgynous and feminine styles. She likes soft fabrics and athletic wear.",
    },
  ];
  

  const userObj = {
    first_name: "Jane",
    last_name: "Doe",
    fullBody: userFullBody,
  };

  const toggleLike = (productId) => {
    setLikedProducts((prev) => ({ ...prev, [productId]: !prev[productId] }));
  };

  const toggleShare = (productId) => {
    setSharedProducts((prev) => ({ ...prev, [productId]: !prev[productId] }));
  };

  const toggleView = (productId) => {
    setViewedProducts((prev) => ({ ...prev, [productId]: !prev[productId] }));
  };

  // Initialize Algolia client
  const searchClient = algoliasearch('GFY9URS8BQ', '3eb21237c021a8a8ba2bb147a42c1a0f');
  const indexName = "items";
  const handleSearch = async (e, query = searchTerm, email = userEmail) => {
    if (e) e.preventDefault();
    setSearchTerm(query);
    setIsSearching(true);
    setTimeElapsed(false);
    setIsStyledLoading(true);
    setIsTraditionalLoading(true);
    setStyledResults([]);
    setTraditionalResults([]);
    setHasSearched(true);
  
    try {
      // Perform STYLD search
      const styledResponse = await axios.post('https://api.styld.ai/demo', {
        prompt: query,
        email: email,
        search_top: 100,
        store_id: selectedStore.replace("h&m","handm"),
      });
  
      setStyledResults(styledResponse.data.items);
      setSearchReason(styledResponse.data.reason || "");
      setIsStyledLoading(false);

      // Update localStorage only after setting the new state
      localStorage.setItem('searchTermB2B', query);
      localStorage.setItem('styledResults', JSON.stringify(styledResponse.data.items));
  
      // Perform traditional search
      await performTraditionalSearch(query);
    } catch (error) {
      console.error('STYLD search error:', error);
      setStyledResults([]);
      setIsStyledLoading(false);
    } finally {
      setIsSearching(false);
    }
  
    trackSearch(query);
  };

  const performTraditionalSearch = async (query) => {
    try {
      const index = searchClient.initIndex(indexName);
      const { hits } = await index.search("demo " + query);
      setTraditionalResults(hits);
      localStorage.setItem('traditionalResults', JSON.stringify(hits));
    } catch (error) {
      console.error('Algolia search error:', error);
      setTraditionalResults([]);
    } finally {
      setIsTraditionalLoading(false);
      setTimeElapsed(true);
    }
  };

  const handleProductClick = (productId, productName) => {
    trackProductClick(productId, productName, searchTerm);
  };

  const traditionalSearchResults = (
    <div className="search-results traditional">
      <h3>Traditional Search Results</h3>
      <div className="results-grid">
        {Array.isArray(traditionalResults) && traditionalResults?.length > 0 ? (
          traditionalResults.map((hit, index) => (
            <div key={hit.objectID} className="result-item">
              <img
                src={hit?.image || prodThumbnail}
                alt={hit?.name || `Product ${index + 1}`}
              />
            </div>
          ))
        ) : (
          <div>No results found</div>
        )}
      </div>
    </div>
  );

  const styledSearchResults = (
<div className="styled-results">
  <img src={Logo} alt="Logo" className="styled-logo" />
  <div className="product-grid">
    {styledResults.map((product, index) => (
      <div
        key={index}
        className="product-item"
        onClick={() => {
          handleProductClick(product.id, product.name);
          window.open(product?.link?.replace("https:/w", "https://w"), "_blank");
        }}
      >
        <a href={product?.link?.replace("https:/w", "https://w")} onClick={(e) => e.preventDefault()}>
          <img
            src={product?.image}
            alt={product?.name}
            className="product-image"
          />
        </a>
      </div>
    ))}
  </div>
</div>
  );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserEmail(user.email);
      } else {
        setUserEmail("");
      }
    });

    return () => unsubscribe();
  }, []);

  const handlePersonalityClick = (personality) => {
    if (selectedPersonality === personality.name) {
      // Deactivate the personality if it's already selected
      setSelectedPersonality(null);
      setUserEmail("");
    } else {
      // Activate the new personality
      setSelectedPersonality(personality.name);
      setUserEmail(personality.email);
      if (searchTerm) {
        // Use the new email directly in the search
        handleSearch(null, searchTerm, personality.email);
      }
    }
  };

  useEffect(() => {
    localStorage.setItem('selectedPersonality', selectedPersonality);
  }, [selectedPersonality]);

  useEffect(() => {
    localStorage.setItem('likedProductsB2B', JSON.stringify(likedProducts));
  }, [likedProducts]);

  useEffect(() => {
    localStorage.setItem('sharedProductsB2B', JSON.stringify(sharedProducts));
  }, [sharedProducts]);

  useEffect(() => {
    localStorage.setItem('viewedProductsB2B', JSON.stringify(viewedProducts));
  }, [viewedProducts]);

  useEffect(() => {
    localStorage.setItem('traditionalResults', JSON.stringify(traditionalResults));
  }, [traditionalResults]);

  useEffect(() => {
    localStorage.setItem('searchTermB2B', searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    localStorage.setItem('styledResults', JSON.stringify(styledResults));
  }, [styledResults]);

  useEffect(() => {
    localStorage.setItem('userEmail', userEmail);
  }, [userEmail]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSuggestionIndex((prevIndex) =>
        (prevIndex + 1) % searchSuggestions.length
      );
    }, 3000); // Change suggestion every 3 seconds

    return () => clearInterval(interval);
  }, [searchSuggestions]);

  useEffect(() => {
    if (!hasSearched) {
      const savedSearchTerm = localStorage.getItem('searchTermB2B');
      const savedStyledResults = localStorage.getItem('styledResults');
  
      if (savedSearchTerm && savedSearchTerm !== 'undefined') {
        setSearchTerm(savedSearchTerm);
      }

      // if (savedStyledResults && savedStyledResults !== 'undefined') {
      //   setStyledResults(JSON.parse(savedStyledResults));
      // }

      // if (savedTraditionalResults && savedTraditionalResults !== 'undefined') {
      //   setTraditionalResults(JSON.parse(savedTraditionalResults));
      // }
    }
  }, [hasSearched]);

  const renderPersonalityButton = (personality, index) => (
    <OverlayTrigger
      key={index}
      placement="top"
      overlay={<Tooltip id={`tooltip-${personality.name}`}>{personality.description}</Tooltip>}
    >
      <button
        className={`personality-button bg-transparent ${selectedPersonality === personality?.name ? 'active' : ''}`}
        onClick={() => handlePersonalityClick(personality)}
      >
        <img src={personality?.image} alt={personality?.name} />
        <span>{personality?.name}</span>
      </button>
    </OverlayTrigger>
  );

  return (
    <div className="demo-b2b-container">
      <div className="demo-b2b-content">
        <div className="search-container">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearch(e, searchTerm); // Trigger search on Enter key
            }
          }}
          placeholder="Enter your search query"
          className="search-input"
        />
        <select
          value={selectedStore}
          onChange={(e) => setSelectedStore(e.target.value)}
          className="store-select"
        >
          {availableStores.map((store) => (
            <option key={store} value={store}>
              {store.toUpperCase()}
            </option>
          ))}
        </select>
          <button
            className="search-button"
            onClick={(e) => handleSearch(e, searchTerm)}
            disabled={isSearching}
          >
            {isSearching ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <>
                <span>Search</span>
                <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.2061 0.664063C8.65141 0.664188 7.11926 1.01714 5.73752 1.69347C4.35577 2.3698 3.16449 3.34989 2.26308 4.55198C1.36166 5.75407 0.77624 7.1433 0.555661 8.60376C0.335081 10.0642 0.485738 11.5536 0.995061 12.9475C1.50438 14.3415 2.35761 15.5997 3.48354 16.6171C4.60947 17.6345 5.97547 18.3816 7.46756 18.7962C8.95966 19.2107 10.5346 19.2806 12.0609 19.0001C13.5873 18.7195 15.0208 18.0966 16.2419 17.1834L20.4309 21.1587C20.6473 21.357 20.937 21.4667 21.2378 21.4642C21.5385 21.4617 21.8262 21.3472 22.0389 21.1454C22.2516 20.9436 22.3722 20.6706 22.3748 20.3852C22.3774 20.0998 22.2618 19.8248 22.0529 19.6195L17.8638 15.6443C18.9971 14.2799 19.7027 12.6404 19.9 10.9133C20.0972 9.18613 19.778 7.4412 18.979 5.87817C18.18 4.31513 16.9334 2.99714 15.382 2.07503C13.8305 1.15292 12.0368 0.663946 10.2061 0.664063ZM2.7503 9.91647C2.7503 8.03996 3.53583 6.24032 4.93406 4.91343C6.3323 3.58654 8.22872 2.8411 10.2061 2.8411C12.1835 2.8411 14.08 3.58654 15.4782 4.9195C16.8764 6.24032 17.662 8.03996 17.662 9.91647C17.662 11.793 16.8764 13.5926 15.4782 14.9195C14.08 16.2464 12.1835 16.9918 10.2061 16.9918C8.22872 16.9918 6.3323 16.2464 4.93406 14.9195C3.53583 13.5926 2.7503 11.793 2.7503 9.91647Z" fill="#F1EFE7"/>
                </svg>
              </>
            )}
          </button>
        </div>
        <div className="search-suggestions">
          {searchSuggestions.map((suggestion, index) => (
            <button
              key={index}
              className="suggestion-button"
              onClick={(e) => {
                handleSearch(e, suggestion);
              }}
            >
              {suggestion}
            </button>
          ))}
        </div>
        <div className="personality-container">
          <p>Or try our existing personality samples:</p>
        <div className="personality-grid">
          {personalities.map((personality, index) => renderPersonalityButton(personality, index))}
        </div>
        </div>
      </div>
      {searchReason && (
          <Alert variant="secondary" className="">
            {searchReason}
          </Alert>
        )}      
      <div className="demos-comparison">
        <div className="results-container">
          <div className="results-column">
            {isStyledLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading STYLD results...</span>
              </Spinner>
            ) : (
              styledSearchResults
            )}
          </div>
          <div className="results-column">
            {isTraditionalLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading traditional results...</span>
              </Spinner>
            ) : (
              traditionalSearchResults
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoB2B;