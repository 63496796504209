import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import './Events.css';
import { db } from "../firebaseConfig";
import { doc, setDoc } from "firebase/firestore";
import { Instagram } from 'react-bootstrap-icons';

import AdamPic from "../assets/images/demo/Adam.png";
import JosePic from "../assets/images/demo/Jose.png";
import HannahPic from "../assets/images/demo/Hannah.png";
import MariaPic from "../assets/images/demo/Maria.png";
import PatriciaPic from "../assets/images/demo/Patricia.png";

const Events = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const events = [
    {
      id: 1,
      title: "Good Looks - NYFW Afterparty",
      date: "2024-09-14T22:00:00",
      image: "https://posh-images-alts-production.s3.amazonaws.com/66e09ebbb43fbe55710accf5/1400x1400.webp",
      link: "https://posh.vip/e/good-looks-nyfw-afterparty-2",
    },
    {
      id: 2,
      title: "Thread Mix w STYLD & Threads of Tech - #LATechWeek",
      date: "2024-10-17T18:30:00",
      image: "https://partiful.imgix.net/user/dPbXuoIFMbQ1xf7kJ3uZ1XdfF932/HGosB2VVfjUVcxDUAfzdg?fit=clip&w=920&auto=format",
      link: "https://partiful.com/e/hmGuri4cjRZv6vedvVtQ",
    },
  ];

  const isPastEvent = (eventDate) => {
    return new Date(eventDate) < new Date();
  };

  const sortedEvents = [...events].sort((a, b) => new Date(b.date) - new Date(a.date));

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        await setDoc(doc(db, "waitlist", email), { email, name, phone });
        setSuccessMessage("Successfully joined the waitlist!");
        setEmail("");
        setName("");
        setPhone("");
      } catch (error) {
        console.error("Error adding to waitlist: ", error);
        setSuccessMessage("Failed to join the waitlist. Please try again.");
      }
    }
  };

  return (
    <Container fluid className="events-container">
      <Row className="justify-content-center align-items-center text-center py-5">
        <Col md={8} xs={12}>
          <div className="circle-logo-container">
            <img src="https://styld.ai/static/media/Logo-blk.e59a8b66baebfe010380.png" className="logo mb-3 circle-logo" alt="Styld Logo" />
          </div>

          <p className="mb-4">
            <strong>A community of fashion enthusiasts, creatives and entrepreneurs</strong>
          </p>
          <div className="profile-images mb-4">
            <img src={AdamPic} alt="Adam" className="profile-image" />
            <img src={JosePic} alt="Jose" className="profile-image" />
            <img src={HannahPic} alt="Hannah" className="profile-image" />
            <img src={MariaPic} alt="Maria" className="profile-image" />
            <img src={PatriciaPic} alt="Patricia" className="profile-image" />
          </div>
          <p className="mb-4">
            <strong>👤 270+ people on list</strong> &nbsp; · &nbsp; <a href="https://www.instagram.com/lookstyld/" style={{ color: "black" }} target="_blank" rel="noopener noreferrer"><Instagram /></a>
          </p>

          <Form onSubmit={handleSubmit} className="waitlist-form mt-4">
            <Form.Control
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="waitlist-input"
            />
            <Form.Control
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="waitlist-input"
              required
            />
            <Form.Control
              type="tel"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="waitlist-input"
            />
            <Button
              type="submit"
              variant="dark"
              className="waitlist-button"
            >
              Join Waitlist
            </Button>
          </Form>
          {successMessage && <p className="mt-3 text-success font-weight-bold">{successMessage}</p>}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={10}>
          <div className="events-scroll-container">
            {sortedEvents.map((event) => (
              <Card 
                key={event.id} 
                className={`event-card ${isPastEvent(event.date) ? 'past-event' : ''}`}
              >
                <div className="event-image-container">
                  <Card.Img variant="top" src={event.image} />
                  {isPastEvent(event.date) && (
                    <span className="past-event-indicator">Happened</span>
                  )}
                </div>
                <Card.Body className="d-flex flex-column">
                  <div>
                    <Card.Title>{event.title}</Card.Title>
                    <Card.Text>{new Date(event.date).toLocaleString()}</Card.Text>
                  </div>
                  <div className="mt-auto pt-3">
                    <Button 
                      variant="primary" 
                      className="join-invite-btn" 
                      href={event.link} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      {isPastEvent(event.date) ? "View Photos" : "Join invite list"}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Events;