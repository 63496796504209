import React from 'react';
import DemoB2C from '../demo/demoB2C/demoB2C';

const DemoB2CAllPage = () => {
  return (
    <div className="container">
      <h1>All Stores Demo</h1>
      <DemoB2C initialStore="all" />
    </div>
  );
};

export default DemoB2CAllPage;