// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCb-4GwWAD8Iotpk9Z8We_65FMd5_nP1FY",
  authDomain: "styld-ai-cloud.firebaseapp.com",
  projectId: "styld-ai-cloud",
  storageBucket: "styld-ai-cloud.appspot.com",
  messagingSenderId: "1000167074368",
  appId: "1:1000167074368:web:0eb23c60ee3d7898bc4da5",
  measurementId: "G-3EVV35YRL8",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
