import React, { useState } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { auth } from "../../firebaseConfig";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
} from "firebase/auth";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

function InWidgetAuthModal({ show, handleClose, onAuthSuccess, isSignup, toggleAuthMode }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      onAuthSuccess(result.user, null);
      handleClose();
    } catch (error) {
      console.error("Google login error: " + error.message);
    }
  };

  const handleEmailPasswordAuth = async () => {
    try {
      let userCredential;
      if (isSignup) {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
        if (phoneNumber) {
          await updateProfile(userCredential.user, {
            phoneNumber: phoneNumber
          });
        }
      } else {
        userCredential = await signInWithEmailAndPassword(auth, email, password);
      }
      onAuthSuccess(userCredential.user, phoneNumber);
      handleClose();
    } catch (error) {
      console.error("Authentication error: " + error.message);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
      <Modal.Header>
        <Modal.Title>{isSignup ? "Sign Up" : "Login"}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ backgroundColor: "#F1EFE7", fontFamily: "Inter, sans-serif" }}
      >
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address:</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                backgroundColor: "transparent",
                border: "2px solid #CECCC5",
                borderRadius: "25px",
                fontFamily: "Inter, sans-serif",
              }}
              className="custom-input"
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password:</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                backgroundColor: "transparent",
                border: "2px solid #CECCC5",
                borderRadius: "25px",
                fontFamily: "Inter, sans-serif",
              }}
              className="custom-input"
            />
          </Form.Group>

          {isSignup && (
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number (optional):</Form.Label>
              <PhoneInput
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={setPhoneNumber}
                defaultCountry="US"
                className="PhoneInput"
                inputClass="PhoneInputInput"
              />
            </Form.Group>
          )}
        </Form>
        <Button
          variant="primary"
          className="w-100 mt-2 custom-button"
          onClick={handleEmailPasswordAuth}
          style={{
            backgroundColor: "#FE3C00",
            borderRadius: "25px",
            fontWeight: "bold",
            fontFamily: "Inter, sans-serif",
            border: "none",
          }}
        >
          {isSignup ? "Sign Up" : "Login"}
        </Button>
        <Button
          variant="outline-secondary"
          className="w-100 mt-2 custom-button-google"
          onClick={handleGoogleLogin}
          style={{
            borderRadius: "25px",
            fontWeight: "bold",
            fontFamily: "Inter, sans-serif",
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            border: "2px solid #CECCC5",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="25"
            height="25"
            viewBox="0 0 48 48"
          >
            <path
              fill="#FFC107"
              d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
            ></path>
            <path
              fill="#FF3D00"
              d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
            ></path>
            <path
              fill="#4CAF50"
              d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
            ></path>
            <path
              fill="#1976D2"
              d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
            ></path>
          </svg>{" "}
          Continue with Google
        </Button>
        <Button
          variant="link"
          className="w-100 mt-2 custom-link"
          onClick={toggleAuthMode}
          style={{ fontFamily: "Inter, sans-serif", color: "#A2A19D" }}
        >
          {isSignup
            ? "Already have an account? Log in"
            : "Don't have an account? Sign up"}
        </Button>
      </Modal.Body>
      <style>
        {`
          .custom-input:hover, .custom-input:focus {
            border-color: #FE3C00 !important;
          }
          .custom-button-google:hover{
            background-color: #CECCC5 !important;
          }
          .custom-button:hover{
            background-color: #FF6637 !important;
          }
          .custom-button:focus {
            background-color: #FE3C00 !important;
            border-color: #FE3C00 !important;
            color: white !important;
          }
          .custom-link:hover, .custom-link:focus {
            color: #FE3C00 !important;
            text-decoration: underline !important;
          }
          .modal-content {
            border-radius: 25px !important;
            overflow: hidden;
          }
          .custom-modal {
            max-width: 500px;
            margin: 1.75rem auto;
          }
          .PhoneInput {
            background-color: transparent;
            border: 2px solid #CECCC5;
            border-radius: 25px;
            padding: 0.375rem 0.75rem;
            font-family: Inter, sans-serif;
          }
          .PhoneInputInput {
            background-color: transparent;
            border: none;
            font-family: Inter, sans-serif;
            color: #000;
          }
          .PhoneInputCountrySelect {
            background-color: transparent;
            border: none;
            font-family: Inter, sans-serif;
          }
          .PhoneInputCountryIcon {
            border: none;
            box-shadow: none;
          }
          .PhoneInput--focus {
            border-color: #FE3C00;
          }
          .PhoneInputInput:focus {
            outline: none;
          }
        `}
      </style>
    </Modal>
  );
}

export default InWidgetAuthModal;