import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  const handleContactClick = (event) => {
    event.preventDefault();
    // Navigate to the contact page
    window.location.href = '/contact';
    // Scroll to top after a short delay to ensure the page has loaded
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  return (
    <footer className="bg-gray-800 text-gray-200 py-4 site-footer">
      <div className="container mx-auto flex justify-between items-center">
        <p>&copy; 2024 STYLD. All rights reserved.</p>
        <RouterLink 
          to="/contact"
          onClick={handleContactClick}
          className="hover:text-gray-400"
        >
          Contact Us
        </RouterLink>
      </div>
    </footer>
  );
};

export default Footer;