import { analytics } from './firebaseConfig';
import { logEvent } from "firebase/analytics";
import { auth } from './firebaseConfig';

const isProduction = process.env.NODE_ENV === 'production';

export const trackEvent = (eventName, eventParams = {}) => {
  if (!isProduction) {
    console.log('Analytics event (dev):', eventName, eventParams);
    return;
  }

  const user = auth.currentUser;
  const userId = user ? user.uid : 'anonymous';

  logEvent(analytics, eventName, {
    ...eventParams,
    user_id: userId,
  });
};

export const trackSearch = (searchTerm) => {
  trackEvent('search', { search_term: searchTerm });
};

export const trackPageView = (pageName) => {
  trackEvent('page_view', { page_name: pageName });
};

export const trackUserEngagement = (engagementType) => {
  trackEvent('user_engagement', { engagement_type: engagementType });
};

export const trackProductClick = (productId, productName, searchQuery) => {
  trackEvent('product_click', { product_id: productId, product_name: productName, search_query: searchQuery });
};
