import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './LiveDemos.css'; // Add this import

const LiveDemos = () => {
  const stores = [
    { name: 'revolve', logo: 'https://1000logos.net/wp-content/uploads/2021/05/Revolve-logo-768x432.png' },
    { name: 'zara', logo: 'https://1000logos.net/wp-content/uploads/2022/08/Zara-log%D0%BE-768x432.png' },
    { name: 'h&m', logo: 'https://1000logos.net/wp-content/uploads/2017/02/HM-Logo-1024x640.png' },
    { name: 'ssense', logo: 'https://www.logo.wine/a/logo/SSENSE/SSENSE-Logo.wine.svg ' },
    { name: 'mcm', logo: 'https://upload.wikimedia.org/wikipedia/commons/5/5f/MCM-logo-new-2020.png ' },
  ];

  return (
    <Container className="my-5">
      <h1 className="text-center mb-4">Try It Yourself</h1>
      <h3 className="text-center mb-5">
        See how STYLD <span>understands</span> and <span>personalizes</span> <br />
        your online shopping experience.
      </h3>

      <Row>
        <Col md={3} className="mb-4">
          <Link to="/demo/b2c/all" className="card-link">
            <Card>
              <Card.Img variant="top" src="https://img.freepik.com/free-vector/e-commerce-development-illustration_335657-4710.jpg?w=1380&t=st=1726671749~exp=1726672349~hmac=12a0d4bb2c54437c632555231e353db25a0f899df13534335c9c4734f2de6bdc" alt="All stores logo" className="contain-image" />
              <Card.Body>
                <Card.Title>All Stores</Card.Title>
              </Card.Body>
            </Card>
          </Link>
        </Col>

        <Col md={3} className="mb-4">
          <Link to="/demo/b2b" className="card-link">
            <Card>
              <Card.Img variant="top" src="https://styld.ai/static/media/Logo-blk.e59a8b66baebfe010380.png" alt="B2B logo" className="contain-image" />
              <Card.Body>
                <Card.Title>Side-by-Side Comparison</Card.Title>
              </Card.Body>
            </Card>
          </Link>
        </Col>

        {stores.map((store) => (
          <Col key={store.name} md={3} className="mb-4">
            <Link to={`/demo/b2c/${store.name}`} className="card-link">
              <Card>
                <Card.Img variant="top" src={store.logo} alt={`${store.name} logo`} className="contain-image" />
                <Card.Body>
                  <Card.Title>{store.name.toUpperCase()}</Card.Title>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default LiveDemos;